interface ButtonPros {
  bgColor?: string;
  textColor?: string;
  size?: string;
  children: React.ReactNode;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  className?: string;
}
export function Button({
  bgColor,
  textColor,
  size,
  onClick,
  children,
  type = "button",
  disabled = false,
  className,
}: ButtonPros) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`px-3 py-2 text-xs font-medium text-center text-white  rounded-lg  focus:ring-4 focus:outline-none focus:ring-blue-300 ${className} ${
        disabled ? "bg-gray-500" : "bg-blue-700 hover:bg-blue-800"
      }`}
    >
      {children}
    </button>
  );
}
