import React, { FC, useState } from "react";
import Heading from "./components/Heading";
import InputField from "./components/InputField";
import { MdAdd } from "react-icons/md";
import StageTitle from "./components/StageTitle";
import { AddOutlined } from "@mui/icons-material";
import { elements_type } from "./constants";

interface RadioInterface {
  onDelete: () => void;
  onChange: (data: any) => void;
  label: string;
  options?: { label: string; value: string }[];
}

const Radio: FC<RadioInterface> = ({ onDelete, label, onChange, options }) => {
  const [data, setData] = useState({
    label: label,
    input_type: elements_type.RADIOBUTTON,
    options: options?.length ? options : [{ label: "", value: "" }],
  });

  const addInput = () => {
    setData((state: any) => ({
      ...state,
      options: [...state.options, { label: "", value: "" }],
    }));
  };

  function onClickDropDown(ind: number, name: string, value: string) {
    const updatedData = { ...data };

    updatedData.options[ind] = { ...updatedData.options[ind], [name]: value };
    setData(updatedData);
    onChange(updatedData);
  }

  return (
    <>
      {/* <StageTitle onDelete={onDelete} /> */}
      <div className="border-[1px] rounded">
        <Heading type={elements_type.RADIOBUTTON} onDelete={onDelete} />
        <div className="p-4">
          <InputField
            label="label"
            value={data.label}
            id="checkBoxName"
            placeHolder="Label"
            onChange={(value) => {
              const updatedData = { ...data };

              updatedData.label = value;
              setData(updatedData);
              onChange(updatedData);
            }}
          />
          <div className="mt-2">
            <h5>Options for Radio Button</h5>
            {data.options?.map((x, ind) => {
              return (
                <div
                  className="flex justify-between mt-2 gap-x-1 items-center"
                  key={ind}
                >
                  <InputField
                    label="label"
                    id="optionName"
                    placeHolder="Label for Option"
                    value={x.label}
                    onChange={(value) => onClickDropDown(ind, "label", value)}
                  />
                  <InputField
                    label="value"
                    id="optionValue"
                    value={x.value}
                    placeHolder="Value for Option"
                    onChange={(value) => onClickDropDown(ind, "value", value)}
                  />
                  <button
                    type="button"
                    onClick={addInput}
                    className="text-blue-700 mt-3 hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full   p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
                  >
                    <AddOutlined />
                    <span className="sr-only">Icon description</span>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Radio;
