import { useEffect, useState } from "react";
import { listProcess, processRequest } from "../../apis/process";
import "reactflow/dist/style.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Stack,
  Grid,
} from "@mui/material";
import toast from "react-hot-toast";
import { Loading } from "../../components/Loading/Loading";
import CardaHome from "../../containers/CardHome/CardHome";
import { Add } from "@mui/icons-material";
import "./home.css";

export function ProcessList() {
  const [processes, setprocesses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProcessId, setSelectedProcessId] = useState<
    undefined | string
  >(undefined);
  const [isLoading, setLoading] = useState(false);

  async function startProcess() {
    setOpenDialog(false);
    setLoading(true);
    try {
      const data: any = await processRequest({ process: selectedProcessId });
      toast.success(data);
    } catch (err) {
      toast.error("Process start failed");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const data = await listProcess();
        setprocesses(data);
      } catch (error) {}
    })();
  }, []);

  return (
    <>
      <div className="bg-[#f6f8fa]">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="border-b-2 px-8"
          paddingY={1}
        >
          <Typography variant="h5">Recent</Typography>
          <Button
            endIcon={<Add />}
            variant="contained"
            disableElevation
            sx={{ textTransform: "none" }}
          >
            Create Flow
          </Button>
        </Stack>
      </div>

      <div className="px-6 py-8 pt-6 bg-[#f6f8fa]">
        <Loading open={isLoading} />
        {/* <div className="grid grid-cols-3 md:grid-cols-3 gap-3"> */}

        <Button
          sx={{ textTransform: "none", backgroundColor: "#e9ecf0" }}
          variant="contained"
          color="inherit"
          disableElevation
          size="small"
        >
          Recently viewed
        </Button>
        <Button size="small" sx={{ textTransform: "none", color: "#929eab" }}>
          Drafts
        </Button>

        {/* <Stack
          direction="row"
          flexWrap="wrap"
          gap={2}
          justifyContent="space-between"
          marginTop={2}
          style={{ flex: "1 1 30%" }}
        > */}
        <Grid container spacing={2}>
          {/* <div className="dashboard-container"> */}
          {Array.isArray(processes) &&
            processes?.map((process: any, index: number) => (
              <Grid item md={6} sm={6} xs={6} lg={4} xl={3}>
                <div
                  // className="dashboard-item"
                  onClick={() => {
                    setSelectedProcessId(process.uuid);
                    setOpenDialog(true);
                  }}
                >
                  <CardaHome
                    name={process?.name}
                    description={process.description}
                    icon={process.icon}
                  />
                </div>
              </Grid>
            ))}
          {/* </div> */}
          {/* </Stack> */}
        </Grid>
        {/* </div> */}

        <Dialog open={openDialog}>
          <DialogContent>
            <Typography variant="h5">
              Are you want to start the process?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setOpenDialog(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={startProcess}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default ProcessList;
