import { Typography } from "@mui/material";
import { MdOutlineSearch } from "react-icons/md";

interface InputProps {
  name: string;
  onChange: (e: any) => void;
  placeHolder?: string;
  label?: string;
  prepend?: React.ReactNode;
}
export function Input({
  name,
  placeHolder,
  onChange,
  label,
  prepend,
}: InputProps) {
  return (
    <div className="flex flex-col w-full">
      {label && <Typography variant="subtitle1">{label}</Typography>}
      <div
        className={`border border-gray-400 rounded-md  my-2 w-full overflow-hidden flex flex-row items-center ${
          prepend ? "pl-2" : ""
        }`}
      >
        {prepend}{" "}
        <input
          type="text"
          name={name}
          placeholder={placeHolder || ""}
          className="w-full p-2"
          onChange={(e) => onChange(e)}
        />
      </div>
    </div>
  );
}
export default Input;
