import { Edge, Node } from "reactflow";
import { CustomNodeTypes } from "../utils/customFlowItems";
import {
  BASE_URL,
  RESIZABLE_NODE,
  WORK_FLOW_EDGE,
  WORK_FLOW_FORM,
  WORK_FLOW_NODE,
} from "./urls";

const token = localStorage.getItem("token");

export async function listWorkFlowNodes(id: string | undefined) {
  return fetch(`${BASE_URL}/workflow/process-detail/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export async function createWorkFlowNode(
  // reactFlowInstance: ReactFlowInstance,
  // reactFlowWrapper: any,
  // event: any,
  // process: any
  { id, ...payload }: any
): Promise<Node> {
  return new Promise((resolve, reject) => {
    try {
      // const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

      const newNode = {
        ...payload,
        // type: CustomNodeTypes.WORKFLOWNODE,
        // data: { label: "New Node" },
        // position: reactFlowInstance.project({
        //   x: event.x - reactFlowBounds.left,
        //   y: event.y - reactFlowBounds.top,
        // }),
        width: payload.style?.width || 316,
        height: payload.style?.height || 51,
        color: payload.style?.color || "#fffff",
      };
      const token = localStorage.getItem("token");

      fetch(WORK_FLOW_NODE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(newNode),
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        });
    } catch (e) {}
  });
}

export async function createResizable(
  // reactFlowInstance: ReactFlowInstance,
  // reactFlowWrapper: any,
  // event: any,
  // process: any
  { id, ...payload }: any
): Promise<Node> {
  return new Promise((resolve, reject) => {
    try {
      // const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

      const newNode = {
        ...payload,
        // type: CustomNodeTypes.WORKFLOWNODE,
        // data: { label: "New Node" },
        // position: reactFlowInstance.project({
        //   x: event.x - reactFlowBounds.left,
        //   y: event.y - reactFlowBounds.top,
        // }),
        width: payload.style?.width || 316,
        height: payload.style?.height || 51,
        color: payload.style?.backgroundColor || "#fffff",
      };
      const token = localStorage.getItem("token");

      fetch(RESIZABLE_NODE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(newNode),
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        });
    } catch (e) {}
  });
}

export async function updateWorkFlowNode(node: any) {
  fetch(
    `${
      node.type === CustomNodeTypes.WORKFLOWNODE
        ? WORK_FLOW_NODE
        : RESIZABLE_NODE
    }${node?.id}/`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(node),
    }
  )
    .then((response) => response.json())
    .then((data) => {});
}

export async function patchWorkFlowNode(node: any) {
  return fetch(
    `${
      node.type === CustomNodeTypes.WORKFLOWNODE
        ? WORK_FLOW_NODE
        : RESIZABLE_NODE
    }${node?.id}/`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(node),
    }
  )
    .then((response) => response.json())
    .then((data) => data);
}

export async function deleteWorkFlowNode(nodeId: string) {
  fetch(`${WORK_FLOW_NODE}${nodeId}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
}

export async function createWorkFlowEdge(edge: Edge) {
  return fetch(`${WORK_FLOW_EDGE}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      source: edge.source,
      target: edge.target,
      label: "test label",
    }),
  })
    .then((response) => response.json())
    .then((data) => data);
}

export async function updateWorkFlowEdge(edge: Edge) {
  return fetch(`${WORK_FLOW_EDGE}${edge.id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({ ...edge }),
  })
    .then((response) => response.json())
    .then((data) => data);
}

export async function deleteWorkFlowEdge(edgeId: string) {
  fetch(`${WORK_FLOW_EDGE}${edgeId}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
}

export async function createForm(nodeId: string, fields: Array<any>) {
  const reqBody = {
    node: nodeId,
    fields: fields,
  };
  fetch(`${WORK_FLOW_FORM}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(reqBody),
  });
}

export async function getFormPreview(nodeId: string) {
  await fetch(`${BASE_URL}/workflow/form-fields/${nodeId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}
