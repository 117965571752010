import { useEffect, useState } from "react";
import FormCheckBox from "../FormElements/CheckBox";
import DropDown from "../FormElements/DropDown";
import FileInput from "../FormElements/FileInput";
import MultiFileInput from "../FormElements/MultiFileInput";
import Radio from "../FormElements/Radio";
import TextField from "../FormElements/TextField";
import Title from "../FormElements/Title";
import Date from "../FormElements/Date";
import Toggle from "../FormElements/Toggle";
import { elements_type } from "../FormElements/constants";

interface FormEditorProps {
  updateFormData: (data: any) => void;
  initialData: any;
}
const FormEditor = ({ updateFormData, initialData }: FormEditorProps) => {
  const [formData, setFormData] = useState<Array<any>>([]);
  const [onDragOverIndex, setOnDragverIndex] = useState<undefined | number>(
    undefined
  );

  function drop(index: number, ev: any) {
    ev.stopPropagation();
    ev.preventDefault();
    var data: string = ev.dataTransfer.getData("type");
    var element: any = ev.dataTransfer.getData("element");
    var element_ind: any = ev.dataTransfer.getData("element_ind");

    setOnDragverIndex(undefined);
    if (element_ind) {
      setFormData((state) =>
        state.filter((st, ind) => ind !== Number(element_ind))
      );
    }

    let arrayItem = {};
    if (element) {
      arrayItem = JSON.parse(element);
    } else {
      let inputType = "";
      inputType = data;
      arrayItem = {
        input_type: inputType,
      };
    }
    let newData;
    setFormData((state) => {
      newData = [...state.slice(0, index), arrayItem, ...state.slice(index)];
      updateFormData(newData);
      return newData;
    });
  }

  const handleDragStart = (ev: any, element: any, index: number) => {
    ev.dataTransfer.setData("element", JSON.stringify(element));
    ev.dataTransfer.setData("element_ind", index);
  };
  function allowDrop(index: number, ev: any) {
    // setOnDragverIndex(index);

    ev.stopPropagation();
    ev.preventDefault();
  }

  let count = 0;
  function dragEnd() {
    setOnDragverIndex(undefined);
  }

  const handleRemove = (index: number) => {
    setFormData((state) => state.filter((data, ind) => ind !== index));
  };

  function onDragStart(index: number) {
    setOnDragverIndex(index);
  }

  function onDragLeave() {
    setOnDragverIndex(undefined);
  }

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  return (
    <div
      className="w-full h-full"
      onDrop={(e) => drop(formData.length, e)}
      onDragOver={(e) => allowDrop(formData.length, e)}
      onDragLeave={onDragLeave}
    >
      {formData.map((formElement, index: number) => {
        return (
          <div
            key={formElement.id}
            onDragOver={(e) => allowDrop(index, e)}
            onDragStart={(e) => handleDragStart(e, formElement, index)}
            onDragEnter={(e) => onDragStart(index)}
            onDragLeave={(e) => e.stopPropagation()}
            onDrop={(e) => drop(index, e)}
            draggable
            className="pt-3"
          >
            <div className="bg-white">
              {onDragOverIndex === index && (
                <div className="mb-2 h-[1px] bg-[#8ed6ff]" />
              )}
              <Element
                formElement={formElement}
                index={index}
                handleRemove={handleRemove}
                formData={formData}
                onDragEnd={dragEnd}
                setFormData={setFormData}
                updateFormData={updateFormData}
              />
            </div>
          </div>
        );
      })}
      <div className="h-4" onDrop={(e) => drop(formData.length, e)}>
        {onDragOverIndex === formData.length && (
          <div className="h-10">
            <div className="mt-2 mb-1 h-[1px] bg-[#8ed6ff]" />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormEditor;

function Element({
  formElement,
  index,
  handleRemove,
  formData,
  setFormData,
  updateFormData,
}: any) {
  function commonprops() {
    return {
      onDelete: () => handleRemove(index),
      onChange: (data: any) => {
        let updatedFormData = [...formData];
        updatedFormData[index] = data;
        setFormData(updatedFormData);
        updateFormData(updatedFormData);
      },
      label: formElement.label,
    };
  }

  switch (formElement.input_type) {
    case elements_type.TITLE:
      return <Title {...commonprops()} title={formElement.title} />;
    case elements_type.TEXTFIELD:
      return (
        <TextField {...commonprops()} placeHolder={formElement.placeholder} />
      );
    case elements_type.CHECKBOX:
      return (
        <FormCheckBox
          {...commonprops()}
          value={formElement.value}
          required={formElement.required}
          readonly={formElement.readonly}
          hidden={formElement.hidden}
        />
      );
    case elements_type.DROPDOWN:
      return <DropDown {...commonprops()} options={formElement.options} />;
    case elements_type.FILEUPLOAD:
      return <FileInput onDelete={() => handleRemove(index)} />;
    case elements_type.RADIOBUTTON:
      return <Radio {...commonprops()} options={formElement.options} />;
    case elements_type.MULTIFILEUPLOAD:
      return <MultiFileInput onDelete={() => handleRemove(index)} />;
    case elements_type.DATE:
      return <Date {...commonprops()} />;
    case elements_type.TOGGLE:
      return <Toggle {...commonprops()} />;
    default:
      return <>Not implemented</>;
  }
}
