import React, { FC, useEffect, useState } from "react";
import Heading from "./components/Heading";
import InputField from "./components/InputField";
import StageTitle from "./components/StageTitle";
import { elements_type } from "./constants";

interface Text {
  onDelete: () => void;
  onChange: (data: any) => void;
  label?: string;
  title: string;
}
const Text: FC<Text> = ({ onDelete, onChange, label, title }) => {
  const [data, setData] = useState({
    label: "",
    placeholder: "",
    element_type: "INP",
    input_type: elements_type.TITLE,
  });

  return (
    <>
      {/* <StageTitle onDelete={onDelete} /> */}
      <div className="border-[1px] rounded-md bg-white">
        <Heading type={elements_type.TITLE} onDelete={onDelete} />
        <div className="p-4">
          <InputField
            value={title || ""}
            label="Title"
            id="textFieldName"
            placeHolder={label || "Label"}
            onChange={(value) => {
              const updatedData = {
                ...data,
                title: value,
              };
              setData(updatedData);
              onChange(updatedData);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Text;
