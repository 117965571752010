import { createTheme } from "@mui/material/styles";
import "./theme.css";

export const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    h3: {
      fontWeight: 800,
      fontSize: "18px",
      color: "#212121",
    },
    h5: {
      fontWeight: 600,
      fontSize: "14px",
      color: "#212121",
    },
    h6: {
      fontWeight: 500,
      fontSize: "14px",
      color: "#212121",
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "12px",
      color: "#212121",
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#212121",
    },
  },
});

export default theme;
