import { LOGIN } from "./urls";

export const login = async (payload: any) => {
  console.log(payload);

  return await fetch(LOGIN, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .catch((err: any) => {
      console.log(err);

      throw new Error(err);
    });
};
