import React, { FC } from "react";
import Heading from "./components/Heading";
import InputField from "./components/InputField";
import CheckBox from "./components/CheckBox";
import StageTitle from "./components/StageTitle";

interface FileInputInterface {
  onDelete: () => void;
}
const FileInput: FC<FileInputInterface> = ({ onDelete }) => {
  return (
    <>
      {/* <StageTitle onDelete={onDelete} /> */}
      <div className="border-[1px] rounded">
        <Heading type="fileUpload" onDelete={onDelete} />
        <div className="p-4">
          <InputField
            label="label"
            id="textFieldName"
            placeHolder="Label"
            onChange={() => {}}
          />
          <div className="flex justify-between">
            <CheckBox label="Required" isChecked={true} onChange={() => {}} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FileInput;
