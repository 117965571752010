import { useCallback, useEffect, useState } from "react";
import { InboxCategory } from "../../components/inbox/InboxCategory/InboxCategory";
import InboxSection from "../../components/inbox/InboxSection/InboxSection";
import { HistoryIcon } from "../../components/inbox/icons/HistoryIcon";
import { InboxIcon } from "../../components/inbox/icons/InboxIcon";
import { ProcessingIcon } from "../../components/inbox/icons/ProcessingIcon";
import { inboxSections } from "../../utils/inbox";
import {
  MdAdd,
  MdFilterList,
  MdInbox,
  MdOutlineAccessTime,
  MdOutlineCreateNewFolder,
  MdOutlineSearch,
  MdOutlineTaskAlt,
} from "react-icons/md";
import Input from "../../components/Input/Input";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import InputField from "../../components/FormElements/components/InputField";
import CheckBox from "../../components/FormElements/components/CheckBox";
import { inboxDetails, listInbox } from "../../apis/inbox";
import { Button } from "../../components/Button/Button";

export function Inbox() {
  const [activeCatagoryIndex, setActiveCategoryIndex] = useState<number>(1);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);
  const [selected, setSelected] = useState<any>(null);
  const [inboxList, setinboxList] = useState<any>([]);
  const [selectedInboxDetails, setSelectedInboxDetails] = useState<any>({});
  const [dialog, setDialog] = useState(false);

  const handleOpen = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };
  const categories = [
    {
      categoryName: "completed",
      categoryLabel: "Completed",
      icon: <MdOutlineTaskAlt size={20} />,
    },
    {
      categoryName: "inbox",
      categoryLabel: "inbox",
      icon: <MdInbox size={20} />,
    },
    {
      categoryName: "processing",
      categoryLabel: "processing",
      icon: <MdOutlineAccessTime size={20} />,
    },
  ];

  const formComponents = (item: any) => {
    const elements: any = {
      TEXT: (
        <InputField
          label={item.label}
          id={item.uuid}
          placeHolder={item.placeholder}
          onChange={() => {}}
        />
      ),
    };

    return elements[item.input_type];
  };

  useEffect(() => {
    getInboxList();
  }, []);

  const getInboxList = async () => {
    try {
      const data = await listInbox();
      console.log(data);
      setinboxList(data);
      // getInboxDetails(data[0].uuid);
    } catch (error) {
      console.log(error);
    }
  };

  const getInboxDetails = async (id: any) => {
    try {
      const data = await inboxDetails(id);
      setSelectedInboxDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = (item: any) => {
    setDialog(true);
  };

  return (
    <div className="flex flex-row h-full">
      {/* <div className="border-r border-gray-200 min-w-[230px] max-w-[280px] bg-white">
        <h4 className="font-bold text-lg mb-4 px-5 pt-5">Inbox</h4>
        <div className="border-b px-5  pb-8">
          {categories?.map((category, index) => (
            <InboxCategory
              key={category?.categoryName}
              name={category?.categoryName}
              label={category?.categoryLabel}
              icon={category?.icon}
              activeIndex={activeCatagoryIndex}
              index={index}
              onClick={(selectedIndex, name) => {
                setActiveCategoryIndex(selectedIndex);
              }}
            />
          ))}
        </div>
        <div className="flex flex-row justify-between px-5 items-center pt-2">
          <Typography variant="h5" className="font-semibold text-md mb-4">
            Create Custom Folder
          </Typography>
          <div>
            <button
              onClick={() => alert("Option for creating new folder")}
              className="hover:bg-gray-300 p-1 rounded-md"
            >
              <MdAdd size={20} />
            </button>
          </div>
        </div>
        <div className="px-5">
          <ul>
            <li className="mb-1">
              <div className="flex flex-row items-center p-2 rounded-md gap-3 hover:cursor-pointer hover:bg-gray-100">
                <MdOutlineCreateNewFolder size={20} />
                <Typography variant="subtitle1" className="ml-2">
                  Folder 1
                </Typography>
              </div>
            </li>
            <li className="mb-1">
              <div className="flex flex-row items-center p-2 rounded-md  gap-3 hover:cursor-pointer hover:bg-gray-100 ">
                <MdOutlineCreateNewFolder size={20} />
                <Typography variant="subtitle1" className="ml-2">
                  Folder 2
                </Typography>
              </div>
            </li>
          </ul>
        </div>
      </div> */}
      <div className="border-r  border-gray-200 min-w-[310px]">
        <div className="p-4  flex flex-row items-center border-b">
          <Input
            name="search"
            onChange={() => {}}
            placeHolder="Search"
            prepend={<MdOutlineSearch size={25} />}
          />
          <button className="hover:bg-gray-300 ml-4 p-1 rounded-md">
            <MdFilterList size={25} />
          </button>
        </div>

        <div>
          {Array.isArray(inboxList) &&
            inboxList?.map((section: any, index: any) => (
              <InboxSection
                key={section?.uuid}
                date={section?.updated_at_date}
                image={"/avatar.svg"}
                label={section?.display_name}
                id={section?.request_id}
                // type={section.type}
                time={section?.updated_at_time}
                activeIndex={activeSectionIndex}
                index={index}
                onClick={(selectedIndex, type) => {
                  setActiveSectionIndex(selectedIndex);
                  getInboxDetails(section?.uuid);
                  setSelected(section);
                }}
              />
            ))}
        </div>
      </div>
      <div className="w-8/12 ">
        <div className="w-full border-b p-5">
          {selected && (
            <div className="flex">
              <div className="px-2 overflow-hidden justify-center   ">
                <img
                  src={"https://i.pravatar.cc/300"}
                  width={35}
                  height={35}
                  alt="icon"
                  className="rounded-full"
                />
              </div>
              <div>
                <Typography variant="h5">{selected?.display_name}</Typography>
                <Typography variant="caption">
                  Request Id:{" "}
                  {`${selected?.request_id} | ${selected?.updated_at_date}  `}
                </Typography>
              </div>
            </div>
          )}
        </div>
        {/* {selected && <div className="px-5 pt-5"> */}
        {selected && (
          <div className="px-5 pt-5">
            <h4 className="font-bold text-lg mb-5">Application Status</h4>
            <div className=" w-2/3">
              <Typography variant="subtitle1" className="ml-2">
                Stage 1 Title
              </Typography>

              {selectedInboxDetails?.form_data?.map((item: any, index: any) => (
                <>{formComponents(item)}</>
              ))}

              <Grid container mt={2} spacing={1}>
                {selectedInboxDetails?.actions?.map((item: any, index: any) => (
                  <Grid item md={6}>
                    <button
                      onClick={() => handleAction(item)}
                      disabled={!item?.active}
                      type="button"
                      className="w-full py-2.5 px-5 mr-2 mb-2 text-sm disabled:opacity-50
   font-medium text-blue-700 focus:outline-none bg-white rounded-lg border border-blue-700 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                    >
                      {item?.name}
                    </button>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to proceed with this action
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//  <div className="border rounded">
//               <Stack
//                 direction={"row"}
//                 alignItems={"center"}
//                 justifyContent={"flex-start"}
//                 padding={1}
//                 gap={1}
//               >
//                 <input
//                   checked={true}
//                   id="checkbox"
//                   type="checkbox"
//                   className="h-4 w-4 text-gray-900"
//                 />
//                 <Typography variant="subtitle1" className="ml-2">
//                   Check Box
//                 </Typography>
//               </Stack>
//               <hr className="h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50" />

//               <Stack padding={3} direction={"column"}>
//                 <InputField
//                   label="Label"
//                   id="checkBoxName"
//                   placeHolder="Label"
//                   onChange={() => {}}
//                 />

//                 <p className="text-gray-700 mb-2 text-md">Settings</p>

//                 <Stack direction={"row"} gap={2}>
//                   <div className="flex items-center justify-center gap-4">
//                     <label className="block text-gray-700 text-sm capitalize">
//                       Required
//                     </label>
//                     <input
//                       checked={true}
//                       id="checkbox"
//                       type="checkbox"
//                       className="h-4 w-4"
//                     />
//                   </div>
//                   <div className="flex items-center justify-center gap-4">
//                     <label className="block text-gray-700 text-sm capitalize">
//                       Readonly
//                     </label>
//                     <input
//                       checked={true}
//                       id="checkbox"
//                       type="checkbox"
//                       className="h-4 w-4"
//                     />
//                   </div>
//                   <div className="flex items-center justify-center gap-4">
//                     <label className="block text-gray-700 text-sm capitalize">
//                       Hidden
//                     </label>
//                     <input
//                       checked={true}
//                       id="checkbox"
//                       type="checkbox"
//                       className="h-4 w-4"
//                     />
//                   </div>
//                 </Stack>
//               </Stack>
//             </div>
