import React, { FC } from "react";

interface InputFieldInterface {
  value?: string;
  label: string;
  id: string;
  placeHolder: string;
  onChange: (value: string) => void;
}

const InputField: FC<InputFieldInterface> = ({
  value,
  label,
  id,
  placeHolder = "Label",
  onChange,
}) => {
  return (
    <div>
      <label
        className="block text-gray-700 text-sm  mb-2 capitalize"
        htmlFor={id}
      >
        {label} *
      </label>
      <input
      value={value}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        id={id}
        placeholder={placeHolder}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default InputField;
