import { BASE_URL, WORK_FLOW_CATEGORIES, WORK_FLOW_PROCESS, WORK_FLOW_REQUEST } from "./urls";
const token = localStorage.getItem("token");
export async function listProcess() {
  return fetch(WORK_FLOW_PROCESS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export async function createProcess(formData: any) {
  try {
    const response = await fetch(WORK_FLOW_PROCESS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function listCategories() {
  return fetch(WORK_FLOW_CATEGORIES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}


// export async function listCategories() {
//   await fetch(`${BASE_URL}/workflow/category/`, {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Token ${token}`,
//     },
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       console.log(data)
//       return data;
//     });
// }

export async function processRequest(payload: any) {
  return await fetch(WORK_FLOW_REQUEST, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      return data;
    });
}
