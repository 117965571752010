import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useId } from "react";
import InputField from "../FormElements/components/InputField";
import { elements_type } from "../FormElements/constants";

interface FormPreviewProps {
  nodeId: string;
  formFields: Array<any>;
}
export function FormPreview({ nodeId, formFields }: FormPreviewProps) {
  const id = useId();

  return (
    <>
      {formFields.length > 0
        ? formFields.map((field, index) => {
            switch (field?.input_type) {
              case elements_type.TITLE:
                return (
                  <Typography textAlign="center" variant="h3">
                    {field.title}
                  </Typography>
                );
              case elements_type.TEXTFIELD:
                return (
                  <InputField
                    label={field.label}
                    placeHolder={field.placeholder}
                    onChange={() => {}}
                    id=""
                  />
                );
              case elements_type.CHECKBOX:
                return (
                  <FormControlLabel
                    control={<Checkbox readOnly={field.readOnly} />}
                    label={field.label}
                    required={field.required}
                  />
                );
              case elements_type.DROPDOWN:
                return (
                  <>
                    <label
                      className="block text-gray-700 text-sm  mb-2 capitalize"
                      htmlFor={id}
                    >
                      {field.label} *
                    </label>
                    <Select
                      onChange={() => {}}
                      fullWidth
                      id={id}
                      sx={{ backgroundColor: "white" }}
                      size="small"
                    >
                      {field.options?.map((element: any) => (
                        <MenuItem value={element.value}>
                          {element.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                );
              case elements_type.RADIOBUTTON:
                return (
                  <FormControl>
                    <label
                      className="block text-gray-700 text-sm  mb-2 capitalize"
                      htmlFor={id}
                    >
                      {field.label} *
                    </label>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      {field?.options.map((element: any) => {
                        return (
                          <FormControlLabel
                            value={element.value}
                            control={<Radio />}
                            label={element.label}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                );
              case elements_type.DATE:
                return (
                  <>
                    <label className="block text-gray-700 text-sm  mb-2 capitalize">
                      {field.label} *
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker />
                    </LocalizationProvider>
                  </>
                );
              case elements_type.TOGGLE:
                return (
                  <FormControlLabel
                    control={<Switch defaultChecked={field.defaultChecked} />}
                    label={field.label}
                    required={field.required}
                  />
                );
            }
          })
        : "Please Save the form to preview"}
    </>
  );
}
