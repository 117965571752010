import { FC, useState } from "react";
import Heading from "./components/Heading";
import InputField from "./components/InputField";
import CheckBox from "./components/CheckBox";
import { elements_type } from "./constants";

interface FileInputInterface {
  onDelete: () => void;
  onChange: (data: any) => void;
  label: string;
}
const DateInput: FC<FileInputInterface> = ({ onDelete, onChange, label }) => {
  const [data, setData] = useState<{
    label: string;
    input_type: string;
  }>({
    input_type: elements_type.DATE,
    label,
  });

  function labelOnchange(value: string) {
    const updatedData = { ...data };

    updatedData.label = value;
    setData(updatedData);
    onChange(updatedData);
  }

  return (
    <>
      {/* <StageTitle onDelete={onDelete} /> */}
      <div className="border-[1px] rounded">
        <Heading type={elements_type.DATE} onDelete={onDelete} />
        <div className="p-4">
          <InputField
            label="label"
            id="textFieldName"
            placeHolder="Label"
            onChange={(value) => labelOnchange(value)}
          />
        </div>
      </div>
    </>
  );
};

export default DateInput;
