import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { MdDragIndicator } from "react-icons/md";
import { elements } from "./constants";

const FormElements = () => {
  const handleDragStart = (ev: any, elementType: string) => {
    ev.dataTransfer.setData("type", elementType);
  };

  return (
    <div className="p-4 flex flex-col max-h-full Properties overflow-auto">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Drag & Drop Elements</Typography>
        <Typography variant="h6">
          <KeyboardDoubleArrowDown />
        </Typography>
      </Stack>
      <div className="flex flex-col gap-3 mt-4">
        {elements.map((element) => (
          <div
            id={element.type}
            key={element.type}
            draggable
            onDragStart={(e) => handleDragStart(e, element.type)}
            className="p-3 border-[1px] border-gray-300 rounded-md flex justify-between gap-1 items-center hover:cursor-grab"
          >
            <div className="flex items-center gap-2">
              <element.icon className="w-5 h-5 text-[#001C39]" />
              <Typography variant="h6">{element.label}</Typography>
            </div>
            <MdDragIndicator className="fill-slate-400" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormElements;
