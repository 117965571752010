import { Node } from "reactflow";
import { backgroundColors } from "./constants";

export enum CustomNodeTypes {
  DEFAULT = "NormalNode",
  WORKFLOWNODE = "WorkFlowNode",
  RESIZABLENODE = "ResizableNode",
}

type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

export const customNodeTypes: {
  [key: string]: WithRequiredProperty<Partial<Node>, "data">;
} = {
  [CustomNodeTypes.DEFAULT]: {
    type: CustomNodeTypes.DEFAULT,
    data: { label: "New Node" },
  },
  [CustomNodeTypes.WORKFLOWNODE]: {
    type: CustomNodeTypes.WORKFLOWNODE,
    data: { label: "New Node" },
  },
  [CustomNodeTypes.RESIZABLENODE]: {
    type: CustomNodeTypes.RESIZABLENODE,
    data: { label: "Resizable Node" },
    style: { backgroundColor: backgroundColors.sky, width: 1200, height: 600 },
  },
};
