import React from "react"
import { Navigate } from "react-router";


export default function CheckLogin({children} : {children : React.ReactNode}){
    const token = localStorage.getItem("token");

    if(token){
        return <Navigate to="/process-list"/>
    }
    return children
}