import { useNavigate } from "react-router";
import { createProcess, listCategories } from "../../../apis/process";
import { BASE_URL } from "../../../apis/urls";
import Input from "../../../components/Input/Input";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";

type processType = {
  name?: string;
  code?: string;
  description?: string;
  remark?: string;
  category?: string | number;
};
export function ProcessCreate() {
  const [process, setProcess] = useState<processType>({});
  const [categories, setCategories] = useState<Array<any>>([]);
  const navigate = useNavigate();

  console.log(process);
  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setProcess((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const reqBody = {
      ...process,
      created_by: 4,
      category: process?.category || categories[0].id,
    };

    const res = await createProcess(reqBody);
    if (res) {
      navigate(`/process-list/flow-builder/${res.uuid}`);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const data = await listCategories();
      console.log(data);
      setCategories(data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="bg-[#f1f6fa] px-8 pt-6 h-full">
      <div className="items-end mb-6 flex flex-wrap justify- ounded-md">
        <div className="flex-grow">
          <Typography variant="h3">Create New Process</Typography>
        </div>
      </div>
      <div className="w-[500px]">
        <form onSubmit={handleSubmit}>
          <Input
            name="name"
            placeHolder="Enter Name"
            label="Name"
            onChange={(e) => handleChange(e)}
          />
          <Input
            name="code"
            placeHolder="Enter Code"
            label="Code"
            onChange={(e) => handleChange(e)}
          />
          <Input
            name="description"
            placeHolder="Enter Description"
            label="Description"
            onChange={(e) => handleChange(e)}
          />
          <Input
            name="remarks"
            placeHolder="Enter Remarks"
            label="Remarks"
            onChange={(e) => handleChange(e)}
          />
          {/* <div>
            <Typography variant="subtitle1">Category</Typography>
            <select
              value={process.category}
              className="w-full p-2  my-2 border-2 border-gray-300 rounded-md "
              name="category"
              onChange={(e) => handleChange(e)}
            >
              <option value={undefined}>Select Category</option>
              {categories &&
                categories?.map(
                  (category: { id: number; name: string }, index) => (
                    <option value={category?.id}>{category.name}</option>
                  )
                )}
            </select>
          </div> */}
          <br />
          <Button
            type="submit"
            variant="contained"
            sx={{ textTransform: "none" }}
          >
            Create
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ProcessCreate;
