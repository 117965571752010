import React, { useLayoutEffect } from "react";
import { Navigate } from "react-router";
import { listProcess } from "../../apis/process";

function ProtectedRoutes({children}: {children : React.ReactNode}){
    const token = localStorage.getItem("token");

    useLayoutEffect(() => {
        (async() => {
            const data = await listProcess();
            console.log(data);
            
        })()
    },[])

    if(token){
        return children;
    }
    else{
       return <Navigate to="/login"/>
    }
}

export default ProtectedRoutes;