import React, { FC, useState } from "react";
import Heading from "./components/Heading";
import InputField from "./components/InputField";
import CheckBox from "./components/CheckBox";
import StageTitle from "./components/StageTitle";
import { elements_type } from "./constants";

interface ToggleInterface {
  onDelete: () => void;
  label: string;
  required?: boolean;
  defaultChecked?: boolean;
  onChange: (data: any) => void;
}
const Toggle: FC<ToggleInterface> = ({ onDelete, label, onChange }) => {
  const [data, setData] = useState({
    label,
    input_type: elements_type.TOGGLE,
    required: false,
    defaultChecked: false,
  });

  function onChangeToggle(name: string, value: string) {
    console.log(value);

    const updatedData = {
      ...data,
      [name]: value,
    };
    setData(updatedData);
    onChange(updatedData);
  }

  return (
    <>
      <div className="border-[1px] rounded mt-2 mb-2">
        <Heading type={elements_type.TOGGLE} onDelete={onDelete} />
        <div className="p-4">
          <InputField
            label="label"
            id="textFieldName"
            placeHolder="Label"
            value={data.label}
            onChange={(value) => onChangeToggle("label", value)}
          />
          <div className="flex justify-between">
            <CheckBox
              label="Required"
              isChecked={data.required}
              onChange={(e) => onChangeToggle("required", e.target.checked)}
            />
            <CheckBox
              label="Default Checked"
              isChecked={data.defaultChecked}
              onChange={(e) =>
                onChangeToggle("defaultChecked", e.target.checked)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Toggle;
