import { SetStateAction, useState } from "react";
import FlowBuilderIcon from "../Icons/FlowBuilderIcon";
import SideMenuItem from "./SideMenuItem";
import ArrowIcon from "../Icons/ArrowIcon";
import { FormattedMessage } from "react-intl";

import {
  MdInbox,
  MdOutlineDashboard,
  MdOutlineHome,
  MdOutlineSettings,
} from "react-icons/md";
import {
  AccountTreeOutlined,
  Dashboard,
  DashboardOutlined,
  Home,
  HomeOutlined,
  InboxOutlined,
} from "@mui/icons-material";

export default function SideMenu({
  toggleMenu,
  setToggleMenu,
}: {
  toggleMenu: boolean;
  setToggleMenu: any;
}) {
  return (
    <div
      className={`border-r-[1px] border-gray-200 h-full py-2 ${
        !toggleMenu
          ? "min-w-[200px] w-[25%] max-w-[250px] transition-width duration-200 ease-out"
          : "w-[83px] transition-width duration-200 ease-in"
      }  `}
    >
      <div className="px-4 border-b border-gray-200 flex ">
        <button
          className="flex flex-row py-4 mx-3"
          onClick={() => {
            setToggleMenu((prev: any) => !prev);
          }}
        >
          <ArrowIcon
            className={`fill-gray-500 transform rtl:transform rtl:rotate-180 ${
              toggleMenu
                ? "transform rotate-180 rtl:transform rtl:rotate-0 "
                : ""
            }`}
          />
          <h4
            className={`pl-2 rtl:pr-2 ${
              !toggleMenu ? "opacity-100" : "opacity-0 text-[0px]"
            } `}
            // transition-opacity duration-500 ease-in
          >
            <FormattedMessage id="menu" />
          </h4>
        </button>
      </div>
      <ul className="mt-2">
        {/* <SideMenuItem
          label="home"
          // icon={<MdOutlineHome size={25} />}
          icon={<HomeOutlined />}
          toggleMenu={toggleMenu}
          path="/home"
        /> */}
        {/* <SideMenuItem
          label="dashboard"
          icon={<DashboardOutlined />}
          toggleMenu={toggleMenu}
          path="/dashboard"
        /> */}
        {/* <SideMenuItem
          label="inbox"
          icon={<InboxOutlined />}
          toggleMenu={toggleMenu}
          path="/inbox"
          submenu={[
            { label: "Completed", onClick: () => {} },
            { label: "In Progress", onClick: () => {} },
          ]}
        /> */}
        <SideMenuItem
          label="flow_builder"
          icon={<AccountTreeOutlined />}
          toggleMenu={toggleMenu}
          path="/process-list"
        />
        {/* <SideMenuItem
          label="heirarchy"
          icon={<AccountTreeOutlined style={{ transform: "rotate(90deg)" }} />}
          toggleMenu={toggleMenu}
          path="/heirarchy"
        />
        <SideMenuItem
          label="settings"
          icon={<MdOutlineSettings fontSize={25} />}
          toggleMenu={toggleMenu}
          path="/settings"
        /> */}
      </ul>
    </div>
  );
}
