import { MenuItem, Select } from "@mui/material";
import React from "react";

function LangDropDOwn({handleOnClick}: {handleOnClick: (e: any) => any}){

    return <>
        <Select defaultValue={"en"} size="small" onChange={handleOnClick}>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="ar">Arabic</MenuItem>
        </Select>
    </>
}

export default LangDropDOwn;