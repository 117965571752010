import React, { FC, useEffect, useState } from "react";
import Heading from "./components/Heading";
import InputField from "./components/InputField";
import StageTitle from "./components/StageTitle";
import { elements_type } from "./constants";

interface TextFieldInterface {
  onDelete: () => void;
  onChange: (data: any) => void;
  label?: string;
  placeHolder?: string;
}
const TextField: FC<TextFieldInterface> = ({
  onDelete,
  onChange,
  label,
  placeHolder,
}) => {
  const [data, setData] = useState({
    label: "",
    placeholder: "",
    element_type: "INP",
    input_type: elements_type.TEXTFIELD,
  });

  return (
    <>
      {/* <StageTitle onDelete={onDelete} /> */}
      <div className="border-[1px] rounded-md bg-white">
        <Heading type={elements_type.TEXTFIELD} onDelete={onDelete} />
        <div className="p-4">
          <InputField
            value={label || ""}
            label="label"
            id="textFieldName"
            placeHolder={label || "Label"}
            onChange={(value) => {
              const updatedData = {
                ...data,
                label: value,
              };
              setData(updatedData);
              onChange(updatedData);
            }}
          />
          <InputField
            value={placeHolder || ""}
            label="placeholder"
            id="placeholderName"
            placeHolder={placeHolder || "Placeholder"}
            onChange={(value) => {
              const updatedData = {
                ...data,
                placeholder: value,
              };
              setData(updatedData);
              onChange(updatedData);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TextField;
