import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  Background,
  Controls,
  Edge,
  MiniMap,
  Node,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import {
  createResizable,
  createWorkFlowEdge,
  createWorkFlowNode,
  deleteWorkFlowEdge,
  listWorkFlowNodes,
  updateWorkFlowNode,
} from "../../apis/flowBuilder";
import FlowItemsMenu from "../../components/FlowItemsMenu/FlowItemsMenu";
import FormBuilder from "../../components/FormBuilder";
import WorkFlowNode from "../../components/WorkFlowNode/WorkFlowNode";
import NodeUserList from "../../containers/NodeUserList/NodeUserList";
import { CustomNodeTypes, customNodeTypes } from "../../utils/customFlowItems";
import { firstSelectedNode } from "../../utils/nodes";
import CustomEdge from "./CustomEdge";
import ResizableNode from "./ResizableNode";
import { Label } from "@mui/icons-material";

let id = 1;
const getId = () => `flowai-${id++}`;

export function WorkFlow() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const nodeTypes = useMemo(
    () => ({
      WorkFlowNode: (props: any) => {
        return (
          <WorkFlowNode
            onUserBtnClick={onUserBtnClick}
            onFormBtnClick={onFormBtnClick}
            selectedNodeId={selectedNodeId}
            {...props}
          />
        );
      },
      ResizableNode: (props: any) => {
        return <ResizableNode {...props} />;
      },
    }),
    []
  );

  const EdgeType: any = {
    AF: (props: any) => <CustomEdge {...props} type="AF" />,
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState<
    CustomNodeTypes | undefined
  >(CustomNodeTypes.WORKFLOWNODE);
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [selectedNode, setSelectedNode] = useState<Node | null>();
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>();
  const reactFlowInstance = useReactFlow();
  const { processId } = useParams();

  const onUserBtnClick = (id: any) => {
    setSelectedNodeId((prevSelectedId) => (prevSelectedId === id ? null : id));
    setSelectedNode(null);  // Ensure the form view is reset when toggling the user list.
  };
  

  const onFormBtnClick = (node: any) => {
    setSelectedNode((prevSelectedNode) => (prevSelectedNode?.id === node.id ? null : node));
    setSelectedNodeId(null);  // Ensure user list is reset when toggling the form view.
  };
  

  useEffect(() => {
    listworkflowNode();
  }, [processId]);

  async function listworkflowNode() {
    try {
      const data: any = await listWorkFlowNodes(processId);
      setNodes(data?.nodes);
      setEdges(data?.edges);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const pane = reactFlowWrapper.current;
    const doubleClickDelay = 300;
    let lastClickTime = 0;

    const onPaneClick = async (event: React.MouseEvent) => {
      {
        // Prevent default pane double-click if a node is clicked
        if ((event.target as HTMLElement).closest('.react-flow__node')) {
          return;
        }
        
        }
      const currentTime = new Date().getTime();
      if (currentTime - lastClickTime < doubleClickDelay) {
        if (!reactFlowWrapper.current) return;
        if (selectedMenuItem) {
          const reactFlowBounds =
            reactFlowWrapper.current.getBoundingClientRect();
          const newNode: Node = {
            ...customNodeTypes[selectedMenuItem],
            id: getId(),
            position: reactFlowInstance.project({
              x: event.clientX - reactFlowBounds.left,
              y: event.clientY - reactFlowBounds.top,
            }),
          };
          setNodes((prev) => prev.concat(newNode));
        }
      }
      lastClickTime = currentTime;
    };

    if (pane) {
      pane.addEventListener("click", onPaneClick as unknown as EventListener);
    }

    return () => {
      if (pane) {
        pane.removeEventListener(
          "click",
          onPaneClick as unknown as EventListener
        );
      }
    };
  }, [reactFlowInstance, selectedMenuItem]);

  useEffect(() => {
    (async () => {
      const lastAddedNode = nodes[nodes.length - 1];
      if (lastAddedNode.id.startsWith("flow")) {
        const payload = { ...lastAddedNode, process: processId };

        if (lastAddedNode.type === CustomNodeTypes.WORKFLOWNODE) {
          const data = await createWorkFlowNode(payload);
          const node = [...nodes];
          node[node.length - 1] = data;
          setNodes(node);
        } else {
          const data = await createResizable(payload);
        }
      }
    })();
  }, [nodes.length]);

  const onConnect = useCallback(
    async (params: any) => {
      try {
        // Check if an edge with the same source and target already exists
        const edgeExists = edges.some(
          (edge) => edge.source === params.source && edge.target === params.target
        );
  
        if (!edgeExists) {
          let newEdges = [...edges, { ...params, label: "test label", type: "AF" }];
          setEdges(newEdges);
          
          // Create workflow edge
          const data = await createWorkFlowEdge(params);
  
          // Add newly created edge to the edges array
          setEdges(prevEdges => [...prevEdges, data]);
        }
      } catch (err) {
        console.log(err);
      }
      // listworkflowNode();
    },
    [edges]
  );
  

  const handleSelectMenuItem = (item: CustomNodeTypes | undefined) => {
    setSelectedMenuItem(item);
  };

  return (
    <>
      <div className="h-full relative flex">
        <div className={`flex-grow w-[200px]`} ref={reactFlowWrapper}>
          <ReactFlow
            // key={nodes.length}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView={false}
            nodeTypes={nodeTypes}
            onConnect={onConnect}
            snapToGrid
            edgeTypes={EdgeType}
            onNodeDragStop={async (event, node) => {
              await updateWorkFlowNode(node);
            }}
            onNodeDoubleClick={() => {
              const thisNode = firstSelectedNode(nodes);
              setSelectedNode(thisNode);
              setSelectedNodeId("");
            }}
            onEdgeClick={(event, edge) => {}}
          >
            <MiniMap />
            <Controls />
            <Background gap={12} size={1} />
          </ReactFlow>
          <FlowItemsMenu onSelectItem={handleSelectMenuItem} />
        </div>
        {selectedNode?.type === "WorkFlowNode" && (
          <div className="w-[730px]">
            <FormBuilder
              selectedNode={selectedNode}
              onClose={() => setSelectedNode(null)}
              listworkflowNode={listworkflowNode}
            />
          </div>
        )}

        {selectedNodeId && (
          <NodeUserList
          onClose={() => setSelectedNodeId("")} // This will close the sidebar
          selectedNodeId={selectedNodeId}
          show={!!selectedNodeId} // Control visibility based on selectedNodeId
          nodeName={selectedNode?.data?.label || "NAMEEEE"}
        />
        )}
        {/* <AIworkFlowIndex /> */}
      </div>
    </>
  );
}
