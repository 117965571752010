import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import MainLayout from "./layouts/MainLayout";

import { ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import ProtectedRoutes from "./components/protectedRoute/ProtectedRoute";
import CheckLogin from "./components/protectedRoute/checkloggin";
import { Herirarchy } from "./pages/heirarchy/heirarchy";
import Home from "./pages/home";
import { Inbox } from "./pages/inbox/inbox";
import { Login } from "./pages/login/Login";
import ProcessCreate from "./pages/process/processCreate/ProcessCreate";
import ProcessList from "./pages/process/processList/ProcessList";
import { Signup } from "./pages/signup/Signup";
import { WorkFlow } from "./pages/workflow/Workflow";
import theme from "./theme";
import GlobalContextProvider from "./context/GlobalContextProvider";

function App() {
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    const url = `https://api.i18nexus.com/project_resources/translations/${locale}/default.json?api_key=${process.env.REACT_APP_I18NEXUS_API_KEY}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMessages(data);
      });
  }, [locale]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalContextProvider>
        <IntlProvider locale={locale} messages={messages || {}}>
          <Toaster />
          <BrowserRouter>
            <ReactFlowProvider>
              <Routes>
                <Route path="" element={<Navigate to="/login" />} />
                <Route
                  path="/login"
                  element={
                    <CheckLogin>
                      <Login />
                    </CheckLogin>
                  }
                />
                <Route path="/signup" element={<Signup />} />

                <>
                  <Route
                    path=""
                    element={
                      <ProtectedRoutes>
                        <MainLayout
                          locale={locale}
                          handleLocaleChange={(updatedLocale) =>
                            setLocale(updatedLocale)
                          }
                        />
                      </ProtectedRoutes>
                    }
                  >
                    {/* <Route path="/unnoted" element={<WorkLayout />} /> */}
                    <Route path="/home" element={<Home />} />
                    <Route path="/process-list" element={<ProcessList />} />
                    <Route
                      path="/process-list/flow-builder/:processId"
                      element={<WorkFlow />}
                    />
                    <Route
                      path="/process-list/create-process"
                      element={<ProcessCreate />}
                    />
                    <Route path="/inbox" element={<Inbox />} />
                    <Route path="/heirarchy" element={<Herirarchy />} />
                    <Route path="*" element={<>404 Not found</>} />
                  </Route>
                </>
              </Routes>
            </ReactFlowProvider>
            {/* <MainLayout
          locale={locale}
          handleLocaleChange={(updatedLocale) => setLocale(updatedLocale)}
          >
          <ReactFlowProvider>
          <Routes>
          <Route path="/home" element={<WorkLayout />} />
          <Route path="/process-list" element={<ProcessList />} />
          <Route
          path="/process-list/flow-builder/:processId"
          element={<WorkFlow />}
          />
          <Route
              path="/process-list/create-process"
              element={<ProcessCreate />}
              />
              <Route path="/inbox" element={<Inbox />} />
              <Route path="/heirarchy" element={<Herirarchy />} />
              </Routes>
              </ReactFlowProvider>
            </MainLayout> */}
          </BrowserRouter>
        </IntlProvider>
      </GlobalContextProvider>
    </ThemeProvider>
  );
}

export default App;
