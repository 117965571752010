import { GETINBOX, GETINBOXDETAILS } from "./urls";
const token = localStorage.getItem("token")


export async function listInbox() {
  return fetch(GETINBOX, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

export async function inboxDetails(id: any) {
  return fetch(`${GETINBOXDETAILS}${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}