import React, { Component, RefObject } from "react";
import "./NodeUserList.css"; // Import sidebar styles
import { listOrganizationStaffs, createNodeUser } from "../../apis/organization"; // Import the new function

// Define the props interface
interface Sidebar2Props {
  show: boolean;
  onClose: () => void; // Function to handle closing the sidebar
  selectedNodeId: string; // Node ID to be passed
  nodeName: string; // Node name
}

// Define the state interface
interface Sidebar2State {
  searchTerm: string;
  selectedCategory: string; // Current selected label
  hierarchy: Array<{
    hierarchy: string;
    users: Array<{
      name: string;
      uuid: string;
      profile_img: string;
      is_selected: string;
    }>;
  }>; // Hierarchy structure
  scrollPosition: number; // For controlling scroll movement
  selectedItems: string[]; // Array to track selected items
  isLoading: boolean; // Loading state
}

class NodeUserList extends Component<Sidebar2Props, Sidebar2State> {
  categoryContainerRef: RefObject<HTMLDivElement>;

  constructor(props: Sidebar2Props) {
    super(props);
    this.state = {
      searchTerm: "",
      selectedCategory: "All", // Initially set to 'All'
      hierarchy: [], // Initialize hierarchy
      scrollPosition: 0, // For controlling scroll movement
      selectedItems: [], // Initialize selected items
      isLoading: true, // Initially loading
    };

    // Reference to category container for scrolling
    this.categoryContainerRef = React.createRef();
  }

  componentDidMount() {
    this.fetchHierarchy(); // Fetch hierarchy on component mount
  }

  componentDidUpdate(prevProps: Sidebar2Props) {
    if (prevProps.selectedNodeId !== this.props.selectedNodeId) {
      // Refetch hierarchy data if a new node is selected
      this.fetchHierarchy();
    }
  }

  fetchHierarchy = async () => {
    try {
      this.setState({ isLoading: true }); // Start loading
      const data = await listOrganizationStaffs(this.props.selectedNodeId); // Fetch hierarchy from your API

      // Get users that are already selected
      const preSelectedUsers = data.staff
        .flatMap(
          (department: {
            hierarchy: string;
            users: Array<{
              name: string;
              uuid: string;
              profile_img: string;
              is_selected: string;
            }>;
          }) => department.users
        )
        .filter(
          (user: { name: string; is_selected: string }) =>
            user.is_selected === "true"
        ) // Check if user is selected
        .map((user: { name: string }) => user.name); // Get the names of the pre-selected users

      // Set the hierarchy and the selectedItems
      this.setState({
        hierarchy: data.staff,
        selectedItems: preSelectedUsers, // Initialize selectedItems with pre-selected users
        isLoading: false, // Stop loading
      });
    } catch (error) {
      console.error("Error fetching hierarchy:", error);
      this.setState({ isLoading: false }); // Ensure loading state is false on error
    }
  };

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleCategoryChange = (category: string) => {
    this.setState({ selectedCategory: category });
  };

  handleScrollLeft = () => {
    const container = this.categoryContainerRef.current;
    if (container) {
      const newScrollPosition = Math.max(this.state.scrollPosition - 100, 0); // Scroll left
      container.scrollTo({ left: newScrollPosition, behavior: "smooth" });
      this.setState({ scrollPosition: newScrollPosition });
    }
  };

  handleScrollRight = () => {
    const container = this.categoryContainerRef.current;
    if (container) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const newScrollPosition = Math.min(
        this.state.scrollPosition + 100,
        maxScrollLeft
      ); // Scroll right
      container.scrollTo({ left: newScrollPosition, behavior: "smooth" });
      this.setState({ scrollPosition: newScrollPosition });
    }
  };

  handleItemClick = async (itemName: string) => {
    this.setState((prevState) => {
      const isSelected = prevState.selectedItems.includes(itemName);
      return {
        selectedItems: isSelected
          ? prevState.selectedItems.filter((name) => name !== itemName) // Deselect item
          : [...prevState.selectedItems, itemName], // Select item
      };
    });

    // Log the selectedNodeId to the console when a user is clicked
    console.log(`Selected Node ID: ${this.props.selectedNodeId}`);

    // Find the user that was clicked
    const clickedUser = this.state.hierarchy
      .flatMap((department) => department.users)
      .find((user) => user.name === itemName);

    // If user is found, call the createNodeUser API
    if (clickedUser) {
      try {
        const response = await createNodeUser(
          this.props.selectedNodeId,
          [clickedUser.uuid]
        );
        console.log("API response:", response);
      } catch (error) {
        console.error("Error calling createNodeUser API:", error);
      }
    }
  };

  render() {
    const { show, onClose } = this.props;
    const { searchTerm, hierarchy, selectedItems, isLoading, selectedCategory } =
      this.state;

    // Render loading state
    if (isLoading) {
      return (
        <div className={`sidebar2 ${show ? "visible" : ""}`}>
          <div className="loading">Loading...</div>
        </div>
      );
    }

    // Prepare category labels
    const categoryLabels = ["All", ...hierarchy.map((dept) => dept.hierarchy)];

    // Filter users based on search term and selected category
    const filteredUsers = hierarchy.flatMap((department) =>
      selectedCategory === "All" || selectedCategory === department.hierarchy
        ? department.users.filter((user) =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : []
    );

    return (
      <div className={`sidebar2 ${show ? "visible" : ""}`}>
        <div className="header">
          <h2>Add User to Node</h2> {/* Updated header */}
        </div>
        
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search...."
            value={searchTerm}
            onChange={this.handleSearchChange}
          />
        </div>

        {/* Category navigation with arrows */}
        <div className="category-navigation">
            <button className="arrow arrow-left" onClick={this.handleScrollLeft}>
                <strong>{"<"}</strong>
            </button>
            <div className="category-container" ref={this.categoryContainerRef}>
                {categoryLabels.map((label, index) => (
                    <button
                        key={index}
                        className={`category-button ${selectedCategory === label ? "active" : ""}`}
                        onClick={() => this.handleCategoryChange(label)}
                    >
                        {label}
                    </button>
                ))}
            </div>
            <button className="arrow arrow-right" onClick={this.handleScrollRight}>
                <strong>{">"}</strong>
            </button>
        </div>


        {/* Users display */}
        <div className="items-grid">
          {filteredUsers.map((user, index) => (
            <div
              key={user.uuid}
              className={`item ${
                selectedItems.includes(user.name) ? "selected" : ""
              }`} // Apply selected class
              onClick={() => this.handleItemClick(user.name)} // Handle item click and log Node ID
            >
              <img
                src={user.profile_img}
                alt={user.name}
                className="item-image"
              />
              <span>{user.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default NodeUserList;
