import { memo, useEffect, useState } from "react";
import { Node } from "reactflow";
import { createForm, patchWorkFlowNode } from "../apis/flowBuilder";
import { BASE_URL } from "../apis/urls";
import EditableText from "./EditableText/EditableText";
import FormEditor from "./FormEditor/FormEditor";
import FormElements from "./FormElements/FormElements";
import { FormPreview } from "./FormPreview/FormPreview";

interface FormBuilderProps {
  selectedNode: Node;
  onClose: () => void;
  listworkflowNode: () => any;
}

const FormBuilder: React.FC<FormBuilderProps> = ({
  selectedNode,
  onClose,
  listworkflowNode,
}) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<Array<any>>([]);

  const handleOnSaveLabel = async (value: string | number) => {
    const updatedNode = {
      ...selectedNode,
      data: { ...selectedNode.data, label: value },
    };
    await patchWorkFlowNode(updatedNode);
    listworkflowNode();
  };

  useEffect(() => {
    fetch(`${BASE_URL}/workflow/form-fields/${selectedNode.id}`)
      .then((response) => response.json())
      .then((data) => {
        setFormFields(data);
      });
  }, []);

  return (
    <div className="w-full h-[calc(100vh_-_4rem)] flex flex-col">
      <div className="flex-grow flex border border-gray-300 overflow-y-auto">
        <div className="flex-grow-[3] flex flex-col p-4 border-r border-gray-300">
          <div className="flex flex-row justify-between">
            <EditableText
              title="Title"
              initialValue={selectedNode.data.label}
              onSave={handleOnSaveLabel}
            />
            <button
              onClick={() => {
                setShowPreview((prev) => !prev);
              }}
              className="text-[#0060AB]"
            >
              {showPreview ? "Form" : "Preview"}
            </button>
          </div>
          <div className="flex-grow border-[1px] rounded-md border-gray-300 px-5 pb-5 mt-4 bg-[#f6f8fa] max-h-full overflow-auto">
            {showPreview === false ? (
              <FormEditor
                updateFormData={(data) => {
                  setFormFields(data);
                }}
                initialData={formFields}
              />
            ) : (
              <FormPreview nodeId={selectedNode.id} formFields={formFields} />
            )}
          </div>
        </div>
        <div className="min-w-[250px]">
          <FormElements />
        </div>
      </div>
      <div className="flex justify-end py-2 py-2.5">
        <button
          type="button"
          className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          onClick={onClose}
        >
          Close
        </button>
        <button
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          onClick={() => {
            createForm(selectedNode.id, formFields);
            setShowPreview(true);
          }}
        >
          {formFields.length > 0 ? "Edit" : "Save"}
        </button>
      </div>
    </div>
  );
};

export default memo(FormBuilder);
